import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import KeyResources from "../../components/common/KeyResources";
import TopFormWithCenter from "../../components/common/knowledge/Header";
import Cards from "../../components/common/knowledge/Cards";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import {GoToPopup} from "../homepage"


export const blogImg = require("../../assets/img/key-resources/bc_1_1.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_1.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_1.png");

export const tick_icon = require("../../assets/img/tick-icon.png");

const resoucesData = [
  {
    image: blogImg,
    header:
      "Leapfrog with Digital Assistants that enable business continuity during COVID-19",
    url:
      "https://blog.workativ.com/get-over-the-pandemic-standstill-leapfrog-with-digital-assistants-that-enable-business-continuity-during-covid-19/",
    link: "Read Blog",
    alt: "blog"
  },
  {
    image: whitepaperImg,
    header:
      "See a difference in the workplace with a modern self-service that’s user friendly and efficient.",
    url: "/conversational-ai-platform/traditional-support",
    link: "Read Whitepaper",
    alt: "whitepaper"
  },
  {
    image: videoImg,
    header: "How to Automate Tier 1 IT Support in Slack",
    url: "https://youtu.be/0MuSj4FPkyA",
    link: "Watch Now",
    alt: "videoImg"
  }
];
export const cardData = [
  {
    href: "#section-1",
    header: "Support through a reactive helpdesk",
    content:
      "Unadvanced helpdesks and ticketing systems are reactive and information is not available for self-consumption or resolutions by users.",
    backgroundClass: "card_one"
  },
  {
    href: "#section-2",
    header: "Automating tickets",
    content:
      "With simple task automation, the number of process breakdown can be reduced. But, in general, the task level automation with ticketing is confined to ticket prioritization and categorization",
    backgroundClass: "card_two"
  },
  {
    href: "#section-3",
    header: "Automated resolution through AI self-service",
    content:
      "Imagine how much easier it would be if repetitive tasks could be completed through a quick chat with a virtual assistant!",
    backgroundClass: "card_three"
  }
];

export default function KnowledgePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState();

  return (
    <>
      <TitleAndMetaTags
        title="Automated resolution of HR & IT Support issues with Conversational AI Chatbot | Workativ Assistant"
        description="Automated resolution of HR and IT Support issues is possible with Workativ Assistant, a Conversational AI Chatbot with Process Automation capabilities."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Automated resolution of HR & IT Support issues with Conversational AI Chatbot | Workativ Assistant"
        ogDescription="Automated resolution of HR and IT Support issues is possible with Workativ Assistant, a Conversational AI Chatbot with Process Automation capabilities."
      />
      <Container>
      <Layout backgroundColor={"--green-bg mb-0"} logoFor="ASSISTANT">
          <>
            <div className="gen_vs_true autoamted_resolution">
              <section
                className="wv-banner wv-landing-page-1 --green-bg wv-banner-h320"
                id="scrollToHome"
              >
                <TopFormWithCenter>
                  <TopFormWithCenter.Content>
                    Automated resolution
                  </TopFormWithCenter.Content>
                  <TopFormWithCenter.Header>
                    AI capabilities combined with automation to offer an
                    automated resolution of IT support issues
                  </TopFormWithCenter.Header>
                 
                  <RequestForm isFooterForm={false} />
                </TopFormWithCenter>
              </section>
              <section className="wv-section-mv-p pb-200 content_para">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <p className="wv-md-heading wv-weight-normal wv-text-primary wv-limited-content mb-4 mx-auto">
                        Today, automation is being used to boost productivity
                        for businesses. Results show that it enables IT to
                        manage workload tasks faster and at the same time
                        improve productivity. Automation is paving the way for
                        better processes and faster turnarounds for end users.
                      </p>
                      <hr className="wv-limited-content wv-section-mv-m mx-auto mt-5 mb-5" />
                      <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mx-auto mb-3">
                        Before automation of helpdesks, rising costs and decline
                        in productivity had become a major concern and required
                        innovative solutions to cut costs. Productivity was far
                        from being realized with a reactive helpdesk that added
                        to no value to end-users and support teams alike.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <Cards cards={cardData} />
              <section className="wv-main-content wv-section-mv-p0">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-12 --sh-sm mb-4">
                      <div className="dropdown wv-dropdown">
                        <button
                          className={`link ${isOpen ? "active" : ""}`}
                          onClick={() => setOpen(!isOpen)}
                        >
                          Explore this articles
                        </button>
                        <div
                          id="myDropdown"
                          className={`accordion-item submenu dropdown-content ${
                            !isOpen ? "collapsed" : ""
                          }`}
                        >
                          <li>
                            <AnchorLink
                              offset={350}
                              className="url_manipulation"
                              href="#section-1"
                            >
                              Support through a reactive helpdesk
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={350}
                              className="url_manipulation"
                              href="#section-2"
                            >
                              Automating tickets
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={330}
                              className="url_manipulation"
                              href="#section-3"
                            >
                              Automated resolution through AI self-service
                            </AnchorLink>
                          </li>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 --hd-sm">
                      <aside className="wv-sidenav max-width-255" id="myHeader">
                        <p className="wv-sm-heading">Explore this article</p>
                        <ul className="mb-5">
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-1"
                              className={
                                isActive ===
                                " Support through a reactive helpdesk"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive(
                                  " Support through a reactive helpdesk"
                                );
                              }}
                            >
                              {" "}
                              Support through a reactive helpdesk
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-2"
                              className={
                                isActive === "Automating tasks"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive("Automating tasks");
                              }}
                            >
                              {" "}
                              Automating tickets
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-3"
                              className={
                                isActive ===
                                "Automated resolution through AI self-service"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive(
                                  "Automated resolution through AI self-service"
                                );
                              }}
                            >
                              {" "}
                              Automated resolution through AI self-service
                            </AnchorLink>
                          </li>
                        </ul>
                        <AnchorLink offset={200} href="#scrollToHome">
                          {" "}
                          Back to top ↑
                        </AnchorLink>
                      </aside>
                    </div>
                    <div className="col-md-9 col-sm-12 col-xs-12">
                      <div className="wv-content-area">
                        <section className="wv-content-section">
                          <div id="section-1">
                            <div className="row">
                              <div className="col">
                                <h4 className="wv-heading-h4 mb-2">
                                  Support through a reactive helpdesk
                                </h4>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3 ">
                                  Unadvanced helpdesks and ticketing systems are
                                  reactive, and information is not available for
                                  self-consumption or resolutions by users. It
                                  involves employees to engage with the helpdesk
                                  team through email, phone, or email. With this
                                  time-consuming system, the clock ticks on and
                                  the resolution time along with it. The ticket
                                  streams are long, and there is a high
                                  dependency on the IT support team to resolve
                                  even simple issues.
                                </p>

                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                  Much time is spent on resolving simple issues,
                                  taking support costs higher while also
                                  lowering employee productivity. Automation
                                  came to the rescue to make support more
                                  efficient, and soon automating tickets was an
                                  advancement in IT support.
                                </p>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="bg_section_knowledge bg_section_1">
                          <div className="container">
                            <div className="row">
                              <div className="col pr-0 pl-0  -call-reflection">
                                <div className="container">
                                  <div className="main-slider-left">
                                    <h1>
                                      Automated resolution is the embodiment of
                                      true automation
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className=" wv-content-section">
                          <div id="section-2">
                            <div className="row">
                              <div className="col">
                                <h4 className="wv-heading-h4 mb-2">
                                  Automating tickets
                                </h4>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                  With simple task automation in the ticketing
                                  system, ticket categorization and dispatching
                                  effort are minimized and, the number of
                                  process breakdowns can be reduced. But in
                                  general, the task level automation with
                                  ticketing is much confined to:
                                </p>
                                <ul className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-4">
                                  <li>Automatic ticket prioritization</li>
                                  <li>Automatic ticket categorization</li>
                                </ul>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-5">
                                  But using advanced technology in ML and AI, an
                                  automatic ticket resolution can also be
                                  achieved. Workativ Assistant is a new-age
                                  virtual assistant that is designed to enhance
                                  support delivery through a true- automation
                                  system backed by AI. While most ITSM systems
                                  focus on simple task automation of tickets,
                                  Workativ Assistant drives end-to-end
                                  automation that truly helps reduce support
                                  costs and improve MTTR.
                                </p>
                              </div>
                            </div>
                          </div>
                          <section className="bg_section_knowledge mb-0">
                            <div className="container">
                              <div className="row">
                                <div className="col pr-0 pl-0  -call-reflection">
                                  <div className="container">
                                    <div className="main-slider-left">
                                      <h1>
                                        Free upto 30% of support capacity for
                                        higher-value tasks.
                                      </h1>
                                      <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                        According to Gartner, IT service desks
                                        utilizing machine learning-enhanced
                                        technology and Artificial Intelligence
                                        will help organizations stay on top of
                                        things and free up to 30% of support
                                        capacity for higher-value tasks.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="wv-section-mv-p  content_para"
                            id="section-3"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="col">
                                  <h4 className="wv-heading-h4 mb-2">
                                    Automated resolution through AI self-service
                                  </h4>
                                  <p className="wv-md-heading wv-weight-normal wv-text-primary wv-limited-content mb-4">
                                    Imagine how much easier it would be to have
                                    repetitive tasks like resetting a user’s
                                    password, onboarding users, requesting
                                    access to new applications, creating email
                                    lists, and many more completed through a
                                    quick chat with a virtual assistant!
                                  </p>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    The IT team would not have to get involved
                                    and resolve the issues because it is taken
                                    care of through Automated Resolution by
                                    Workativ Assistant.
                                  </p>

                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-5">
                                    Automated resolution takes control of a
                                    problem, instead of requiring steps to be
                                    performed manually. As a result, employees
                                    obtain swift and deft resolutions for their
                                    IT issues. Automated resolution is the
                                    embodiment of true automation.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="wv-section-mv-p  content_para">
                            <div className="container">
                              <div className="row">
                                <div className="col">
                                  <h4 className="wv-heading-h4 mb-2">
                                    More value through automated resolution
                                  </h4>
                                  <p className="wv-md-heading wv-weight-normal wv-text-primary wv-limited-content mb-4">
                                    Workativ Assistant’s automated resolution
                                    capabilities not only help resolve IT
                                    issues; it can also take control and
                                    complete tasks more efficiently.
                                  </p>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    For instance, Workativ Assistant can take
                                    care of the Onboarding process completely
                                    for new employees, relieving HR, and IT
                                    teams of the tasks involved in the
                                    Onboarding process. Workativ Assistant
                                    automates the end-to-end Onboarding through
                                    the integration of corresponding business
                                    applications.
                                  </p>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    All the necessary tasks, such as adding new
                                    employee account, provisioning email,
                                    password, and giving access to applications,
                                    are completed in no time. Faster processing
                                    via the virtual assistant helps onboard new
                                    employees within a day, and the HR and IT
                                    teams save time on such recurring day-to-day
                                    tasks.
                                  </p>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content  mb-3">
                                    Workativ Assistant harnesses AI capabilities
                                    combined with automation to offer a complete
                                    help desk automation for organizations. The
                                    virtual assistant can be tailored to suit an
                                    organization’s needs, and employee
                                    user-experience improves when issues and
                                    requests are resolved instantly.
                                  </p>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    With AI and automation capabilities,
                                    Workativ Assistant brings real value in
                                    terms of being a future-proof, sustainable,
                                    low-cost, timely, and automated ticket
                                    resolution system for IT support.
                                  </p>

                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-5">
                                    See how Workativ Assistant brings better
                                    functionality to your helpdesk making
                                    support more efficient for your employees.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </section>

                          <section className="bg_section_knowledge mb-0">
                            <div className="container">
                              <div className="row">
                                <div className="col pr-0 pl-0  -call-reflection">
                                  <div className="container">
                                    <div className="main-slider-left">
                                      <h1>
                                        Future-proof, sustainable, low-cost,
                                        timely and automated ticket resolution
                                        system.
                                      </h1>
                                      <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                        With AI and automation capabilities,
                                        Workativ Assistant brings true value in
                                        terms of being a future-proof,
                                        sustainable, low-cost, timely and
                                        automated ticket resolution system for
                                        IT support.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          <section className="wv-section-mv-p content_para">
                            <div className="container">
                              <div className="row">
                                <p className="wv-md-heading wv-weight-normal wv-text-primary wv-limited-content mb-4">
                                  All the necessary tasks such as adding new
                                  employee account, provisioning email and
                                  password and giving access to applications can
                                  be completed in no time. This helps onboard
                                  new employees within a day, and helps the HR
                                  and IT teams save time on such recurring
                                  day-to-day tasks.
                                </p>

                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-5">
                                  Workativ Assistant harnesses AI capabilities
                                  combined with automation to offer a complete
                                  help desk automation for organizations. With a
                                  virtual assistant that can be thoroughly
                                  tailored to suit an organization’s need,
                                  employees can feel more comfortable while
                                  engaging with and getting issues and requests
                                  resolved with the Workativ Assistant.
                                </p>
                              </div>
                            </div>
                          </section>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
          <KeyResources cards={resoucesData} />
          <GoToPopup />
          <RequestForm isFooterForm={true} />
        </Layout>
        {/* <OnScrollPopup /> */}
      </Container>
    </>
  );
}
